import React, { useContext, useEffect } from "react";
import { User } from "../user_context";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const Auth = () => {
  const [codeAuth, setCodeAuth] = useState<string | null>("");
  const [codeUsed, setCodeUsed] = useState(false);
  const navigate = useNavigate();

  // Previous implementation
  const { twitterUsernameState } = useContext(User);
  const [twitterUsername, setTwitterUsername] = twitterUsernameState;

  // new implementation

  const { setAuth } = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const extractedCode = queryParams.get("code");
    setCodeAuth(extractedCode);
  }, []);

  useEffect(() => {
    console.log("code", codeAuth);
    async function sendCode() {
      console.log("sending code");
      const response = await axios.post("auth/callback", {
        code: codeAuth,
      });
      const responseData = await response.data;
      console.log(response);
      console.log(responseData);
      setTwitterUsername(responseData.twitter_username);
      setAuth({
        authToken: response.data.access_token,
        expiresAt: response.data.expires_at,
        refreshToken: response.data.refresh_token,
        twitterId: response.data.twitter_id,
        twitterName: response.data.twitter_name,
        twitterUserName: response.data.twitter_username,
      });
    }
    if (codeAuth !== "" && codeUsed === false) {
      console.log("double check entry condition: code - ", codeAuth);
      setCodeUsed(true);
      sendCode();
      navigate("/communities");
    }
  }, [codeAuth]);

  return (
    <div>
      <p>Value of code: {codeAuth}</p>
    </div>
  );
};

export default Auth;
