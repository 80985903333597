import React from "react";
import "./style.css";

interface Props {
  indicatorName: string;
  indicatorValue: number | string | null;
  indicatorFrequency: string;
  indicatorSale?: boolean;
}

const CommunityOverviewStat: React.FC<Props> = ({
  indicatorName,
  indicatorValue,
  indicatorFrequency,
  indicatorSale = false,
}) => {
  return (
    <div
      className="community_stat"
      style={{ backgroundColor: indicatorSale ? "#28AD6E" : "#18516E" }}
    >
      <span className="community_stat_subtitle">{indicatorName}</span>
      <span className="community_stat_value">{indicatorValue}</span>
      <span className="community_stat_reference">{indicatorFrequency}</span>
    </div>
  );
};

export default CommunityOverviewStat;
