import React, { useEffect, useState } from "react";
import CommunityOverviewStat from "./CommunityOverviewStat";
import WomenRise from "../assets/women_rise.jpg";
import WebsiteIcon from "../assets/website_icon.svg";
import InstagramIcon from "../assets/instagram_icon.svg";
import DiscordIcon from "../assets/discord_icon.svg";
import { useQuery, gql } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const GET_COMMUNITY_AND_INDICATORS = gql`
  query GetCommunityAndIndicators($communityName: String!) {
    community(community_name: $communityName) {
      name
      twitter_username
      twitter_description
      twitter_followers
      twitter_following
      website_url
      discord_url
      instagram_url
    }
    community_indicators(community_name: $communityName) {
      interactions_last_seven_days
      ratio_tweets_volume_daily
      collection_indicators {
        collection_id
        collection_name
        ratio_avg_price_daily
        ratio_sales_volume_daily
      }
    }
  }
`;

interface CommunityDetails {
  name: string;
  twitter_username: string;
  twitter_description: string;
  twitter_followers: number;
  twitter_following: number;
  website_url: string;
  discord_url: string;
  instagram_url: string;
}

interface CollectionIndicators {
  collection_id: number;
  collection_name: string;
  ratio_avg_price_daily: number;
  ratio_sales_volume_daily: number;
}

interface CommunityIndicators {
  interactions_last_seven_days: number;
  ratio_tweets_volume_daily: number;
  collection_indicators: CollectionIndicators[];
}

interface Props {
  collectionOrderNumber: number;
}

const CommunityOverview: React.FC<Props> = ({ collectionOrderNumber }) => {
  const { communityName } = useParams();
  console.log("community Name: ", communityName);

  const [communityDetails, setCommunityDetails] = useState<CommunityDetails>({
    name: "",
    twitter_username: "",
    twitter_description: "",
    twitter_followers: 0,
    twitter_following: 0,
    website_url: "",
    discord_url: "",
    instagram_url: "",
  });

  const [communityIndicators, setCommunityIndicators] =
    useState<CommunityIndicators>({
      interactions_last_seven_days: 0,
      ratio_tweets_volume_daily: 0,
      collection_indicators: [],
    });

  const { loading, error, data } = useQuery(GET_COMMUNITY_AND_INDICATORS, {
    variables: { communityName },
    skip: !communityName,
  });

  useEffect(() => {
    if (!loading && data) {
      const communityDetails = data?.community;
      const communityIndicators = data?.community_indicators;

      // console.log(communityDetails);
      // console.log(communityIndicators);

      setCommunityDetails(communityDetails);
      setCommunityIndicators(communityIndicators);
    }
  }, [loading]);

  return (
    <div className="community_overview">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="community_overview_info">
            <div style={{ marginLeft: "20px", marginTop: "20px" }}>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "10px",
                }}
                src={`https://tribetracker-public.s3.eu-west-1.amazonaws.com/community-profile-pictures/${communityDetails.name}.jpeg`}
                alt="Community image"
              />
              {/* <h3>@{communityDetails.twitter_username}</h3> */}
              <Typography variant="h6" sx={{ mt: 1 }}>
                @{communityDetails.twitter_username}
              </Typography>
              <Box sx={{ display: "flex", mt: 1 }}>
                <IconButton
                  sx={{ background: "white" }}
                  href={communityDetails.website_url}
                  target="_blank"
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={WebsiteIcon}
                    alt="Website icon"
                  />
                </IconButton>
                <IconButton
                  sx={{ background: "white" }}
                  href={communityDetails.instagram_url}
                  target="_blank"
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={InstagramIcon}
                    alt="Instagram icon"
                  />
                </IconButton>
                <IconButton
                  sx={{ background: "white" }}
                  href={communityDetails.discord_url}
                  target="_blank"
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={DiscordIcon}
                    alt="Discord icon"
                  />
                </IconButton>
              </Box>
            </div>
          </div>
          <div className="community_overview_stats">
            <h3
              style={{
                color: "black",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "21px",
              }}
            >
              {communityDetails.twitter_description}
            </h3>
            <div style={{ display: "block", marginTop: "30px" }}>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <CommunityOverviewStat
                  indicatorName={"Followers"}
                  indicatorValue={communityDetails.twitter_followers.toLocaleString()}
                  indicatorFrequency={"@ Last week"}
                />
                <CommunityOverviewStat
                  indicatorName={"Following"}
                  indicatorValue={communityDetails.twitter_following}
                  indicatorFrequency={"@ Last week"}
                />
                <CommunityOverviewStat
                  indicatorName={"Ratio Avg. Price"}
                  indicatorValue={`${communityIndicators.collection_indicators[collectionOrderNumber]?.ratio_avg_price_daily}%`}
                  indicatorFrequency={"@ Last day"}
                  indicatorSale={true}
                />
              </div>
              <div style={{ display: "flex" }}>
                <CommunityOverviewStat
                  indicatorName={"Interactions"}
                  indicatorValue={
                    communityIndicators.interactions_last_seven_days
                  }
                  indicatorFrequency={"@ Last 7 days"}
                />
                <CommunityOverviewStat
                  indicatorName={"Ratio Tweets Volume"}
                  indicatorValue={`${communityIndicators.ratio_tweets_volume_daily}%`}
                  indicatorFrequency={"@ Last day"}
                />
                <CommunityOverviewStat
                  indicatorName={"Ratio Sales Volume"}
                  indicatorValue={`${communityIndicators.collection_indicators[collectionOrderNumber]?.ratio_sales_volume_daily}%`}
                  indicatorFrequency={"@ Last day"}
                  indicatorSale={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CommunityOverview;
