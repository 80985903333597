import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "../providers/AuthProvider";
import { useAuth } from "../providers/AuthProvider";
import { Box, Button, Typography } from "@mui/material";

interface Props {
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header: React.FC<Props> = ({ setSignIn }) => {
  const navigate = useNavigate();
  const handleSignIn = () => {
    setSignIn(true);
    document.body.style.overflowY = "hidden";
  };
  const loggedIn = useIsLoggedIn();
  const { auth } = useAuth();

  return (
    <Box
      sx={{
        background: "#18516E",
        color: "white",
        display: "flex",
        height: "60px",
        // width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: "0 2rem",
      }}
    >
      <Typography
        variant="h6"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      >
        Logo
      </Typography>
      {/* <Typography
        variant="h6"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate("/ranking")}
      >
        Ranking
      </Typography> */}
      <Typography variant="h6">FAQ</Typography>
      <Box>
        {loggedIn ? (
          <Button
            variant="contained"
            onClick={() => navigate("/communities")}
            sx={{
              backgroundColor: "#FFA07A",
              "&:hover": {
                backgroundColor: "#FF7F50",
              },
            }}
          >
            @{auth?.twitterUserName}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSignIn}
            sx={{
              backgroundColor: "#6495ED",
              "&:hover": {
                backgroundColor: "#1E90FF",
              },
            }}
          >
            Sign In
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Header;
