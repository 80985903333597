import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import MySpace from "./pages/MySpace";
import Ranking from "./pages/Ranking";
import Community from "./pages/Community";
import Communities from "./pages/Communities";
import CommunityIndex from "./pages/CommunityIndex";
import Header from "./components/Header";
import MiniDrawer from "./pages/CustomDrawer";
import "./App.css";
import AuthProvider, { useIsLoggedIn } from "./providers/AuthProvider";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

interface Props {
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const PublicRoute: React.FC<Props> = ({ setSignIn }) => {
  return (
    <div>
      <Header setSignIn={setSignIn} />
      <Outlet />
    </div>
  );
};

const ProtectedRoute: React.FC = () => {
  const loggedIn = useIsLoggedIn();
  if (loggedIn) {
    return (
      <div>
        <MiniDrawer>
          <Outlet />
        </MiniDrawer>
      </div>
    );
  }
  return (
    <MiniDrawer>
      <div>You are not logged in, please sign in to continue</div>
    </MiniDrawer>
  );
};

const App: React.FC = () => {
  const [signIn, setSignIn] = useState<boolean>(false);
  const [isLogging, setIsLogging] = useState<boolean>(false);
  const [authorizationUrl, setAuthorizationUrl] = useState<string>("");

  useEffect(() => {
    async function handleAuth() {
      const response = await axios.get("auth/login");
      const responseData = await response.data;
      setAuthorizationUrl(responseData.authorization_url);
    }
    handleAuth();
    if (authorizationUrl !== "") {
      window.location.replace(authorizationUrl);
    }
  }, [isLogging]);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route element={<PublicRoute setSignIn={setSignIn} />}>
            <Route
              path="/"
              element={
                <Home
                  signIn={signIn}
                  setSignIn={setSignIn}
                  setIsLogging={setIsLogging}
                />
              }
            />
            <Route
              path="ranking"
              element={
                <Ranking
                  signIn={signIn}
                  setSignIn={setSignIn}
                  setIsLogging={setIsLogging}
                />
              }
            />
          </Route>
          <Route path="auth" element={<Auth />} />
          <Route element={<ProtectedRoute />}>
            <Route path="my-space" element={<MySpace />} />
            <Route path="communities" element={<Communities />}>
              <Route index element={<CommunityIndex />} />
              <Route path=":communityName" element={<Community />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
