import { useState, useEffect, useCallback } from 'react';

/**
 * A prefix to identify session and local storage keys saved using
 * the storage hooks in this application.
 */
const STORAGE_KEYS_PREFIX = 'VERY_GOOD_TOOL_';

// const useStorage = (storage: Storage, keyPrefix: string) => <T>(storageKey: string, fallbackState: T):
//   [T , React.Dispatch<React.SetStateAction<T>> ] => {
  

//   const storedString = storage.getItem(keyPrefix + storageKey);
  
//   const parsedObject = null;
  
//   const [value, setValue] = useState<T>(parsedObject ?? fallbackState);
  
//     useEffect(() => {
//       storage.setItem(keyPrefix + storageKey, JSON.stringify(value));
//     }, [value, storageKey])
//     ;
//   if (storedString !== null) parsedObject = JSON.parse(storedString);
  

//   return [value, setValue];
// };

/**
 * Saves data in local storage.
 * @param storageKey A string to identify the value being being cached.
 * @param fallbackState The default value when no value has been stored yet.
 * @returns A stateful value, and a function to update it.
 * @example 
 * const [collapsed, setCollapsed] = useLocalStorage('isSidebarCollapsed', false);
 */
// const useLocalStorage = useStorage(localStorage, STORAGE_KEYS_PREFIX);


function useLocalStorage<T>(key: string): [T | undefined, (value?: T) => void ] {
  const prefixedKey = STORAGE_KEYS_PREFIX + key;
  const storedString = localStorage.getItem(prefixedKey);
  const storedObject = storedString ? JSON.parse(storedString): undefined;

  const [cachedValue, setCachedValue] = useState<T | undefined>(storedObject);


  const setValue = useCallback((newValue?: T) => {
    if(newValue){
      localStorage.setItem(prefixedKey, JSON.stringify(newValue));
    } else {
      localStorage.removeItem(prefixedKey);
    }
    setCachedValue(newValue);
  }, [setCachedValue])

  console.log("returning value", cachedValue)
  return [cachedValue, setValue]


  
}


export default useLocalStorage;





function func<T>(a: T): T {
  console.log(a);
  return a;
}

const myVar = "2"

func(3)

