import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const top10Communities = [
  { name: "8sian" },
  { name: "Azuki" },
  { name: "BFF" },
  { name: "Boss Beauties" },
  { name: "Cool Cats" },
  { name: "Crypto Chicks" },
  { name: "Crypto Coven" },
  { name: "Deadfellaz" },
  { name: "Doodles" },
  { name: "Fame Lady Squad" },
  { name: "Flower Girls" },
  { name: "Hug" },
  { name: "Moonbirds" },
  { name: "Mystic Sisterhood" },
  { name: "Not Your Bro" },
  { name: "Pudgy Penguins" },
  { name: "Rebel Society" },
  { name: "RTFKT" },
  { name: "Renga" },
  { name: "Surge Women" },
  { name: "Women and Weapons" },
  { name: "Women Rise" },
  { name: "World of Women" },
];

const SearchBar = () => {
  const navigate = useNavigate();

  function parseName(name: string) {
    return name.toLowerCase().replace(/ /g, "_");
  }

  const handleCommunityRedirection = (name: string | null) => {
    if (name) {
      navigate(`${parseName(name)}`);
    }
  };
  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      sx={{
        width: 350,
      }}
      options={top10Communities.map((option) => option.name)}
      onChange={(event, value) => handleCommunityRedirection(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            background: "white",
            borderRadius: "10px",
            "& fieldset": { border: "none" },
          }}
          placeholder="Search communities"
        />
      )}
    />
  );
};

export default SearchBar;
