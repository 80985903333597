import React from "react";
import CommunityOverview from "../components/CommunityOverview";
import Testo from "../components/CommunityVolumeChart";
import CommunityCollection from "../components/CommunityCollection";
import GradeIcon from "@mui/icons-material/GradeOutlined";
import DiamondIcon from "@mui/icons-material/DiamondOutlined";
import CommunityVolume from "../components/CommunityVolume";
import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import CommunityTopNews from "../components/CommunityTopNews";

// interface Props {
//   communityId: number;
// }

const Community = () => {
  const [startDate, setStartDate] = useState("2023-01-29");
  const [endDate, setEndDate] = useState("2023-02-12");
  const [collectionOrderNumber, setCollectionOrderNumber] = useState(0);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ paddingBottom: "20px" }}>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3
                style={{
                  fontFamily: "Kavoon",
                  fontSize: "20px",
                  color: "#18516E",
                  fontWeight: "normal",
                  marginBottom: "10px",
                }}
              >
                Overview
              </h3>
              {/* <GradeIcon />
              <DiamondIcon /> */}
            </div>
            <CommunityOverview collectionOrderNumber={collectionOrderNumber} />
          </div>
          <div>
            <h3
              style={{
                fontFamily: "Kavoon",
                fontSize: "20px",
                color: "#18516E",
                fontWeight: "normal",
                marginLeft: "20px",
                marginBottom: "10px",
              }}
            >
              Collection
            </h3>
            <CommunityCollection
              collectionOrderNumber={collectionOrderNumber}
              setCollectionOrderNumber={setCollectionOrderNumber}
            />
          </div>
        </div>
        <h3
          style={{
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#18516E",
            fontWeight: "normal",
            marginLeft: "20px",
            marginBottom: "10px",
          }}
        >
          Volume
        </h3>
        <CommunityVolume
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          collectionOrderNumber={collectionOrderNumber}
        />
      </div>
      <div style={{ paddingBottom: "20px" }}>
        <h3
          style={{
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#18516E",
            fontWeight: "normal",
            marginLeft: "20px",
            marginBottom: "10px",
          }}
        >
          Top News
        </h3>
        <CommunityTopNews startDate={startDate} endDate={endDate} />
      </div>
    </div>
  );
};

export default Community;
