import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  communityName: string;
  communityValue: string;
  category: string;
  imageUrl: string;
}

const StyledPaper = styled(Paper)({
  borderRadius: 5,
  width: 200,
  height: 200,
  bgcolor: "#fff",
  transition: "box-shadow 0.3s ease-out", // Add transition for smoother effect
  "&:hover": {
    boxShadow: "0px 0px 20px 0px #18516E",
  },
});

const PaperCard: React.FC<Props> = ({
  communityName,
  communityValue,
  category,
  imageUrl,
}) => {
  return (
    <Link
      to={`${communityValue}`}
      style={{ textDecoration: "none", display: "inline-block" }}
    >
      <StyledPaper
        sx={{ borderRadius: 5, width: 200, height: 200, bgcolor: "#fff" }}
      >
        <img
          src={`https://tribetracker-public.s3.eu-west-1.amazonaws.com/community-profile-pictures/${imageUrl}`}
          alt=""
          style={{
            width: 130,
            display: "block",
            margin: "0 auto",
            paddingTop: "16px",
          }}
        />
        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}
        >
          {communityName}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "center", fontSize: "14px" }}
        >
          {category}
        </Typography>
      </StyledPaper>
    </Link>
  );
};

const data = [
  {
    communityName: "Women Rise",
    communityValue: "women_rise",
    category: "Community",
    imageUrl: "women_rise.jpeg",
  },
  {
    communityName: "Flower girls",
    communityValue: "flower_girls",
    category: "Community",
    imageUrl: "flower_girls.jpeg",
  },
  {
    communityName: "8sian",
    communityValue: "8sian",
    category: "Community",
    imageUrl: "8sian.jpeg",
  },
  {
    communityName: "Deadfellaz",
    communityValue: "deadfellaz",
    category: "Community",
    imageUrl: "deadfellaz.jpeg",
  },
  {
    communityName: "Boss Beauties",
    communityValue: "boss_beauties",
    category: "Community",
    imageUrl: "boss_beauties.jpeg",
  },
  {
    communityName: "Crypto Coven",
    communityValue: "crypto_coven",
    category: "Community",
    imageUrl: "crypto_coven.jpeg",
  },
  {
    communityName: "Women and Weapons",
    communityValue: "women_and_weapons",
    category: "Community",
    imageUrl: "women_and_weapons.jpeg",
  },
  {
    communityName: "World of Women",
    communityValue: "world_of_women",
    category: "Community",
    imageUrl: "world_of_women.jpeg",
  },
  {
    communityName: "Surge Women",
    communityValue: "surge_women",
    category: "Community",
    imageUrl: "surge_women.jpeg",
  },
  {
    communityName: "My BFF",
    communityValue: "bff",
    category: "Community",
    imageUrl: "bff.jpeg",
  },
  {
    communityName: "Rebel Society",
    communityValue: "rebel_society",
    category: "Community",
    imageUrl: "rebel_society.jpeg",
  },
  {
    communityName: "Mystic Sisterhood",
    communityValue: "mystic_sisterhood",
    category: "Community",
    imageUrl: "mystic_sisterhood.jpeg",
  },
  {
    communityName: "Not Your Bro",
    communityValue: "not_your_bro",
    category: "Community",
    imageUrl: "not_your_bro.jpeg",
  },
  {
    communityName: "Crypto Chicks",
    communityValue: "crypto_chicks",
    category: "Community",
    imageUrl: "crypto_chicks.jpeg",
  },
  {
    communityName: "Fame Lady Squad",
    communityValue: "fame_lady_squad",
    category: "Community",
    imageUrl: "fame_lady_squad.jpeg",
  },
  {
    communityName: "Hug",
    communityValue: "hug",
    category: "Community",
    imageUrl: "hug.jpeg",
  },
  {
    communityName: "Azuki",
    communityValue: "azuki",
    category: "Community",
    imageUrl: "azuki.jpeg",
  },
  {
    communityName: "Cool Cats",
    communityValue: "cool_cats",
    category: "Community",
    imageUrl: "cool_cats.jpeg",
  },
  {
    communityName: "Doodles",
    communityValue: "doodles",
    category: "Community",
    imageUrl: "doodles.jpeg",
  },
  {
    communityName: "Moonbirds",
    communityValue: "moonbirds",
    category: "Community",
    imageUrl: "moonbirds.jpeg",
  },
  {
    communityName: "Pudgy Penguins",
    communityValue: "pudgy_penguins",
    category: "Community",
    imageUrl: "pudgy_penguins.jpeg",
  },
  {
    communityName: "Renga",
    communityValue: "renga",
    category: "Community",
    imageUrl: "renga.jpeg",
  },
  {
    communityName: "RTFKT",
    communityValue: "rtfkt",
    category: "Community",
    imageUrl: "rtfkt.jpeg",
  },
  {
    communityName: "Vee Friends",
    communityValue: "vee_friends",
    category: "Community",
    imageUrl: "vee_friends.jpeg",
  },
  {
    communityName: "Invisible Friends",
    communityValue: "invisible_friends",
    category: "Community",
    imageUrl: "invisible_friends.jpeg",
  },
  {
    communityName: "Cyber Brokers",
    communityValue: "cyber_brokers",
    category: "Community",
    imageUrl: "cyber_brokers.jpeg",
  },
  {
    communityName: "Rektguy",
    communityValue: "rektguy",
    category: "Community",
    imageUrl: "rektguy.jpeg",
  },
  {
    communityName: "Lazy Lions",
    communityValue: "lazy_lions",
    category: "Community",
    imageUrl: "lazy_lions.jpeg",
  },
  {
    communityName: "Valhalla",
    communityValue: "valhalla",
    category: "Community",
    imageUrl: "valhalla.jpeg",
  },
  {
    communityName: "Psychedelics Anonymous",
    communityValue: "psychedelics_anonymous",
    category: "Community",
    imageUrl: "psychedelics_anonymous.jpeg",
  },
  {
    communityName: "WonderPals",
    communityValue: "wonderpals",
    category: "Community",
    imageUrl: "wonderpals.jpeg",
  },
  {
    communityName: "Llamaverse",
    communityValue: "llamaverse",
    category: "Community",
    imageUrl: "llamaverse.jpeg",
  },
  {
    communityName: "Sneaky Vampire Syndicate",
    communityValue: "sneaky_vampire_syndicate",
    category: "Community",
    imageUrl: "sneaky_vampire_syndicate.jpeg",
  },
  {
    communityName: "Robotos",
    communityValue: "robotos",
    category: "Community",
    imageUrl: "robotos.jpeg",
  },
  {
    communityName: "Coolman Universe",
    communityValue: "coolman_universe",
    category: "Community",
    imageUrl: "coolman_universe.jpeg",
  },
  {
    communityName: "Cryptoadz",
    communityValue: "cryptoadz",
    category: "Community",
    imageUrl: "cryptoadz.jpeg",
  },
  {
    communityName: "Cyber Kongz",
    communityValue: "cyber_kongz",
    category: "Community",
    imageUrl: "cyber_kongz.jpeg",
  },
  {
    communityName: "Desperate Apewives",
    communityValue: "desperate_apewives",
    category: "Community",
    imageUrl: "desperate_apewives.jpeg",
  },
  {
    communityName: "Akutars",
    communityValue: "akutars",
    category: "Community",
    imageUrl: "akutars.jpeg",
  },
  {
    communityName: "Rug Radio",
    communityValue: "rug_radio",
    category: "Community",
    imageUrl: "rug_radio.jpeg",
  },
];

const PaperCardGrid = () => {
  return (
    <Grid container spacing={5}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={2.2} key={index}>
          <PaperCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

const CommunityIndex = () => {
  return (
    <div
      id="community_index"
      style={{ marginTop: "40px", marginBottom: "20px" }}
    >
      <Box>
        <PaperCardGrid />
      </Box>
    </div>
  );
};

export default CommunityIndex;
