import { Box } from "@mui/system";
import React from "react";

const RankingIndicator = () => {
  return (
    <div className="ranking_indicator_box">
      <div
        style={{
          display: "block",
          width: "50%",
          marginTop: "15px",
          marginLeft: "30px",
        }}
      >
        <span className="ranking_indicator_key">Followers</span>
        <span className="ranking_indicator_value">1,423,930</span>
      </div>
      <div style={{ display: "block", marginTop: "15px" }}>
        <Box display="block" marginTop="sm"></Box>
        <span className="ranking_indicator_rate_value">2.1%</span>
        <span className="ranking_indicator_time">Last 24 Hr</span>
      </div>
    </div>
  );
};

export default RankingIndicator;
