import React from "react";
import VGTIcon from "../assets/vgt_icon.png";
import TwitterIcon from "../assets/twitter_icon.png";
import "../App.css";

interface Props {
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogging: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginModal: React.FC<Props> = ({ setSignIn, setIsLogging }) => {
  const handleSignIn = () => {
    setSignIn(false);
    document.body.style.overflowY = "unset";
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLogging(true);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        zIndex: 100,
      }}
    >
      <div className="login_card">
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginRight: "15px",
          }}
        >
          <button
            type="button"
            style={{
              cursor: "pointer",
              backgroundColor: "#7163BA",
              border: "none",
              color: "white",
              borderRadius: "5px",
              width: "25px",
              height: "25px",
              fontWeight: "bold",
              fontSize: "15px",
              marginTop: "15px",
            }}
            onClick={handleSignIn}
          >
            X
          </button>
        </div>

        <img src={VGTIcon} alt="icon" className="login_icon_modal" />
        <h3 className="login_title">Login easily</h3>
        <div
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <button
            className="login_submit"
            type="button"
            onClick={(e) => handleLogin(e)}
          >
            <div
              style={{
                verticalAlign: "middle",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingTop: "0.25rem" }}>
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={TwitterIcon}
                  alt="Twitter icon"
                />
              </span>
              <div style={{ paddingLeft: "15px" }}>Sign in with Twitter</div>
            </div>
          </button>
          <p className="login_terms">
            By clicking the button above, you agree to our Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
