import { Chart } from "react-chartjs-2";
import { useState, useEffect, useRef } from "react";
import { ExampleChartData2W } from "./exampleData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";
import { useQuery, gql } from "@apollo/client";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
);

interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor?: string;
  borderWidth: number;
}

interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

// interface Props {
//   chartData: number[];
//   chartAvgPrice: number[];
//   chartDates: string[];
//   chartOptions: {
//     title?: string;
//     min?: number;
//     max?: number;
//     stepSize?: number;
//   };
// }

interface ChartItem {
  date: string;
  volume_tweets_posted: number;
  volume_tweets_received: number;
  volume_sales: number;
  volume_sales_daily_avg?: number;
}

interface Props {
  chartTimeFrameData: ChartItem[];
  chartActive: string;
  chartOptions: {
    title?: string;
    min?: number;
    max?: number;
    stepSize?: number;
  };
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const Testo: React.FC<Props> = ({
  chartTimeFrameData,
  chartActive,
  chartOptions,
  setStartDate,
  setEndDate,
}) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  // const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const chartData = () => {
    if (chartActive === "tweets") {
      return chartTimeFrameData.map(
        (data) => data.volume_tweets_posted + data.volume_tweets_received
      );
    } else if (chartActive === "activity") {
      return chartTimeFrameData.map((data) => data.volume_sales);
    } else {
      return chartTimeFrameData.map((data) => data.volume_sales);
    }
  };

  useEffect(() => {
    if (hoverIndex !== null) {
      if (hoverIndex === 0) {
        const startDate = chartTimeFrameData[hoverIndex].date;
        setStartDate(startDate);
      } else {
        const startDate = chartTimeFrameData[hoverIndex - 1].date;
        setStartDate(startDate);
      }
      const endDate = chartTimeFrameData[hoverIndex].date;
      setEndDate(endDate);
    }
  }, [hoverIndex]);

  const plotData = {
    labels: chartTimeFrameData.map((data) => data.date),
    datasets: [
      {
        label: "Avg. Price",
        data: chartTimeFrameData.map((data) => data.volume_sales_daily_avg),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255, 99, 132, 0.5)",
        yAxisID: "y1",
        type: "line" as const,
        spanGaps: true,
      },
      {
        label: chartOptions.title,
        data: chartData(),
        // backgroundColor: ["#65A4DD"],
        backgroundColor: chartData().map((_, index) =>
          index === hoverIndex ? "#4c6ef5" : "#65A4DD"
        ),
        borderWidth: 2,
        yAxisID: "y",
        type: "bar" as const,
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
    },
    stacked: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    onHover: (event: any, chartElement: any) => {
      if (event.type == "click") {
        if (chartElement.length > 0) {
          setHoverIndex(chartElement[0].index);
        } else {
          setHoverIndex(null);
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          text: chartOptions.title,
          display: true,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        type: "linear" as const,
        display: true,
        position: "left" as const,
        // min: chartOptions.min,
        // max: chartOptions.max,
        // ticks: {
        //   stepSize: chartOptions.stepSize,
        // },
      },
      y1: {
        title: {
          text: "Avg. Price (ETH)",
          display: true,
          font: {
            size: 16,
            weight: "bold",
          },
        },
        type: "linear" as const,
        display: true,
        position: "right" as const,
      },
    },
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <Chart type="bar" data={plotData} options={options} />
    </div>
  );
};

export default Testo;
