import React, { useEffect, useState } from "react";
import Testo from "../components/CommunityVolumeChart";
import { CircularProgress } from "@mui/material";
import {
  ExampleChartData1W,
  ExampleChartData2W,
  ExampleChartData1M,
} from "./exampleData";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { NumberList } from "aws-sdk/clients/iot";

const GET_VOLUME = gql`
  query GetVolume($communityName: String!) {
    tweets_and_sales_volume_by_community(community_name: $communityName) {
      collection_id
      tweets_and_sales_volume {
        time_frame
        time_series {
          date
          volume_tweets_posted
          volume_tweets_received
          volume_sales
          volume_sales_daily_avg
        }
      }
    }
  }
`;

interface Props {
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  collectionOrderNumber: number;
}

enum TimeFrame {
  TWO_WEEKS = "2W",
  ONE_WEEK = "W",
  ONE_MONTH = "M",
}

interface TimeSeriesData {
  date: string;
  volume_tweets_posted: number;
  volume_tweets_received: number;
  volume_sales: number;
  volume_sales_daily_avg: number;
}

interface TweetsAndSalesVolume {
  time_frame: TimeFrame;
  time_series: TimeSeriesData[];
}

interface TweetsAndSalesVolumeCommunity {
  collection_id: number;
  tweets_and_sales_volume: TweetsAndSalesVolume[];
}

const CommunityVolume: React.FC<Props> = ({
  setStartDate,
  setEndDate,
  collectionOrderNumber,
}) => {
  const { communityName } = useParams();

  const { loading, error, data } = useQuery(GET_VOLUME, {
    variables: { communityName },
  });

  const [tweetsAndSalesVolumeCommunity, setTweetsAndSalesVolumeCommunity] =
    useState<TweetsAndSalesVolumeCommunity[]>([]);

  const [tweetsAndSales1W, setTweetsAndSales1W] = useState([]);
  const [tweetsAndSales2W, setTweetsAndSales2W] = useState([]);
  const [tweetsAndSales1M, setTweetsAndSales1M] = useState([]);

  useEffect(() => {
    if (!loading) {
      setTweetsAndSalesVolumeCommunity(
        data.tweets_and_sales_volume_by_community
      );
    }
  }, [loading]);

  useEffect(() => {
    if (tweetsAndSalesVolumeCommunity.length > 0) {
      // Default to the 2 week timeseries data
      setChartTimeFrameData(
        tweetsAndSalesVolumeCommunity[collectionOrderNumber]
          .tweets_and_sales_volume[1].time_series
      );
    }
  }, [collectionOrderNumber, tweetsAndSalesVolumeCommunity]);

  const [chartActive, setChartActive] = useState("tweets");
  const [chartTimeFrameData, setChartTimeFrameData] = useState<
    TimeSeriesData[]
  >([]);

  const [chartOptions, setChartOptions] = useState({
    title: "Tweets",
    min: 0,
    max: 6000,
    stepSize: 1000,
  });

  const [chartTimeFrame, setChartTimeFrame] = useState("2W");

  const handleTimeFrame1Week = () => {
    setChartTimeFrame("1W");
    setChartTimeFrameData(
      tweetsAndSalesVolumeCommunity[collectionOrderNumber]
        .tweets_and_sales_volume[0].time_series
    );
  };

  const handleTimeFrame2Week = () => {
    setChartTimeFrame("2W");
    setChartTimeFrameData(
      tweetsAndSalesVolumeCommunity[collectionOrderNumber]
        .tweets_and_sales_volume[1].time_series
    );
  };

  const handleTimeFrame1Month = () => {
    setChartTimeFrame("1M");
    setChartTimeFrameData(
      tweetsAndSalesVolumeCommunity[collectionOrderNumber]
        .tweets_and_sales_volume[2].time_series
    );
  };

  const handleRetrieveTweets = () => {
    const options = {
      title: "Tweets",
      min: 0,
      max: 6000,
      stepSize: 1000,
    };
    setChartActive("tweets");
    setChartOptions(options);
  };

  const handleRetrieveActivity = () => {
    const options = {
      title: "Activity",
      min: 0,
      max: 9600,
      stepSize: 1600,
    };
    setChartActive("activity");
    setChartOptions(options);
  };

  const handleRetrieveSales = () => {
    const options = {
      title: "Sales",
      min: 0,
      max: 2400,
      stepSize: 400,
    };
    setChartActive("sales");
    setChartOptions(options);
  };

  return (
    <div
      style={{
        width: "1230px",
        background: "white",
        borderRadius: "10px",
        display: "flex",
      }}
    >
      <div
        style={{
          width: "1000px",
          height: "525px",
          marginBottom: "0px",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Testo
            chartTimeFrameData={chartTimeFrameData}
            chartActive={chartActive}
            chartOptions={chartOptions}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginLeft: "40px",
          marginTop: "0px",
        }}
      >
        <div style={{ display: "flex" }}>
          <button
            className="community_time_frame_button"
            style={{
              background: chartTimeFrame === "1W" ? "#18516E" : "#508FB0",
              color: "white",
              border: "none",
              width: "45px",
              height: "25px",
              cursor: "pointer",
              fontSize: "12px",
              marginRight: "2px",
              borderRadius: "8px 0 0 8px",
            }}
            onClick={handleTimeFrame1Week}
          >
            1W
          </button>
          <button
            className="community_time_frame_button"
            style={{
              background: chartTimeFrame === "2W" ? "#18516E" : "#508FB0",
              color: "white",
              border: "none",
              width: "45px",
              height: "25px",
              cursor: "pointer",
              marginRight: "2px",
              fontSize: "12px",
            }}
            onClick={handleTimeFrame2Week}
          >
            2W
          </button>
          <button
            className="community_time_frame_button"
            style={{
              background: chartTimeFrame === "1M" ? "#18516E" : "#508FB0",
              color: "white",
              border: "none",
              width: "45px",
              height: "25px",
              cursor: "pointer",
              fontSize: "12px",
              borderRadius: "0 8px 8px 0",
            }}
            onClick={handleTimeFrame1Month}
          >
            1M
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            marginLeft: "auto",
            marginRight: "auto",
            height: "80%",
          }}
        >
          <button
            className="community_chart_button"
            style={{
              background: chartActive === "tweets" ? "#18516E" : "#508FB0",
            }}
            onClick={handleRetrieveTweets}
          >
            Tweets
          </button>
          <button
            className="community_chart_button"
            style={{
              background: chartActive === "activity" ? "#18516E" : "#508FB0",
            }}
            onClick={handleRetrieveActivity}
          >
            Activity
          </button>
          <button
            className="community_chart_button"
            style={{
              background: chartActive === "sales" ? "#18516E" : "#508FB0",
            }}
            onClick={handleRetrieveSales}
          >
            Sales
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommunityVolume;
