import React, { useEffect } from "react";
import OpenseaIcon from "../assets/opensea_icon.svg";
import LooksIcon from "../assets/looks_icon.svg";
import EthereumIcon from "../assets/ethereum_icon.svg";
import { useQuery, gql } from "@apollo/client";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { DataArrayOutlined } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { NumberList } from "aws-sdk/clients/iot";

const GET_COLLECTION_DETAILS = gql`
  query GetVolume($communityName: String!) {
    collection_details(community_name: $communityName) {
      collection_id
      collection_name
      supply
      floor
      total_sales_volume
      contract_address
    }
  }
`;

interface CollectionDetails {
  collection_id: number;
  collection_name: string;
  supply: number;
  floor: number;
  total_sales_volume: number;
  contract_address: string;
}

interface Props {
  collectionOrderNumber: number;
  setCollectionOrderNumber: React.Dispatch<React.SetStateAction<number>>;
}

const CommunityCollection: React.FC<Props> = ({
  collectionOrderNumber,
  setCollectionOrderNumber,
}) => {
  const { communityName } = useParams();

  const { loading, error, data } = useQuery(GET_COLLECTION_DETAILS, {
    variables: { communityName },
  });

  const [collectionDetails, setCollectionDetails] = useState<
    CollectionDetails[]
  >([]);

  useEffect(() => {
    if (!loading && data) {
      setCollectionDetails(data.collection_details);
    }
  }, [loading]);

  // const [collectionDetails, setCollectionDetails] = useState({
  //   collection_name: "",
  //   supply: 0,
  //   floor: 0,
  //   total_sales_volume: 0,
  //   contract_address: "",
  // });
  const handleCollectionChange = (value: CollectionDetails | null) => {
    if (value) {
      const selectedIndex = collectionDetails.findIndex(
        (option) => option.collection_name === value.collection_name
      );
      setCollectionOrderNumber(selectedIndex);
    }
  };

  return (
    <div className="community_collection">
      {/* <span style={{ fontSize: "18px", color: "#5A507F", fontWeight: "bold" }}>
        Women Rise
      </span> */}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {collectionDetails.length === 1 ? (
            <span
              style={{ fontSize: "18px", color: "#5A507F", fontWeight: "bold" }}
            >
              {collectionDetails[collectionOrderNumber]?.collection_name}
            </span>
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={collectionDetails}
              getOptionLabel={(option) => option.collection_name}
              onChange={(event, value) => handleCollectionChange(value)}
              value={collectionDetails[collectionOrderNumber] || null}
              sx={{ width: 300, height: 35 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontWeight: "bold",
                      color: "#5A507F",
                      fontSize: "18px",
                      borderRadius: "10px",
                      height: 35,
                      verticalAlign: "middle",
                      paddingTop: 0,
                    },
                  }}
                />
              )}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "60%",
              paddingTop: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "14px",
                  color: "#7163BA",
                  fontWeight: "500",
                }}
              >
                Supply
              </span>
              <span style={{ fontSize: "18px" }}>
                {collectionDetails[
                  collectionOrderNumber
                ]?.supply.toLocaleString()}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "14px",
                  color: "#7163BA",
                  fontWeight: "500",
                }}
              >
                Floor
              </span>
              <span style={{ fontSize: "18px" }}>
                {collectionDetails[collectionOrderNumber]?.floor} ETH
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span
                style={{
                  fontSize: "14px",
                  color: "#7163BA",
                  fontWeight: "500",
                }}
              >
                Volume Transactions
              </span>
              <span style={{ fontSize: "18px" }}>
                {Math.round(
                  collectionDetails[collectionOrderNumber]?.total_sales_volume
                )}{" "}
                ETH
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "5px",
            }}
          >
            <div style={{ display: "block" }}>
              <span style={{ display: "block", fontSize: "14px" }}>
                Marketplaces
              </span>
              <div style={{ paddingTop: "10px" }}>
                <button style={{ background: "white", border: "none" }}>
                  <img
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    src={OpenseaIcon}
                    alt="OpenSea icon"
                  />
                </button>
                <button style={{ background: "white", border: "none" }}>
                  <img
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    src={LooksIcon}
                    alt="LooksRare icon"
                  />
                </button>
              </div>
            </div>
            <div>
              <Typography variant="subtitle1">Chain</Typography>
              <IconButton
                sx={{ p: 0 }}
                href={`https://etherscan.io/address/${collectionDetails[collectionOrderNumber]?.contract_address}`}
                target="_blank"
              >
                <img
                  style={{ width: "24px", height: "40px", cursor: "pointer" }}
                  src={EthereumIcon}
                  alt="Ethereum icon"
                />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CommunityCollection;
