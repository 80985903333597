import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { Card, CardContent, CardMedia } from "@mui/material";
import LoginModal from "../components/LoginModal";
import { height } from "@mui/system";

interface Props {
  signIn: boolean;
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogging: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeroSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-start",
        height: "100vh",
        padding: "2rem",
        backgroundColor: "#18516E",
        flexWrap: "wrap",
        color: "#fff",
      }}
    >
      <Box
        sx={{
          maxWidth: "45%",
          minWidth: "300px",
          textAlign: "left",
          paddingTop: "10vh",
          // height: "100vh",
          // paddingTop: "5%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bold",
            fontSize: "75px",
            lineHeight: "1.2",
            marginBottom: "2rem",
          }}
        >
          Join NFT Communities with Confidence
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bold",
            lineHeight: "1.2",
            marginBottom: "6.5rem",
          }}
        >
          Unlock Social Media & Blockchain Insights
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "bold",
            lineHeight: "1.2",
            marginBottom: "2.5rem",
          }}
        >
          We are on a mission to give you easy-to-read analytics from your
          favourite NFT tribes combining social media metrics with blockchain
          data.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#E2DEEB",
            color: "#18516E",
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          Unlock for free
        </Button>
      </Box>
      <Box
        sx={{
          maxWidth: "45%",
          minWidth: "300px",
          paddingTop: "10vh",
        }}
      >
        {/* Replace this with your actual time series chart image */}
        <img
          src="time_series_hero.jpg"
          alt="Time series chart"
          style={{
            maxWidth: "100%",
            // maxHeight: "100%",
            borderRadius: "15px",
            // marginTop: "5%",
          }}
        />
      </Box>
    </Box>
  );
};

const Features = () => {
  const featureList = [
    {
      title: "Get Tribe KPIs/indicators based on Twitter activities",
      imageSrc: "feature_1.png",
    },
    {
      title:
        "Find the best opportunities to join or leave a tribe comparing social media engagement and sales volumes at once",
      imageSrc: "feature_2.png",
    },
    {
      title: "Understand your tribe history and its key moments",
      imageSrc: "feature_3.png",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "2rem",
        backgroundColor: "#18516E",
        flexWrap: "wrap",
      }}
    >
      {featureList.map((feature, index) => (
        <Card
          key={index}
          sx={{
            maxWidth: 300,
            minWidth: 280,
            backgroundColor: "#E2DEEB",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.25)",
            margin: "1rem",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            alignItems: "center",
            height: 450,
          }}
        >
          <CardMedia
            component="img"
            // width="240px"
            width="50px"
            height="auto"
            image={feature.imageSrc}
            alt={feature.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ fontSize: "18px", maxWidth: "250px" }}
            >
              {feature.title}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

const ComingSoon = () => {
  const comingSoonList = [
    {
      title:
        "Tribe Pulse: the best place to feel the pulse of the overall market (bull or bear) and get our tribes ranking based on their entry price level and social media engagement",
      imageSrc: "soon_1.png",
    },
    {
      title:
        "My space: save your favorite tribes and have personalized indicators",
      imageSrc: "soon_2.png",
    },
    {
      title: "Explore: broaden your exploration journey in the space",
      imageSrc: "soon_3.png",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "2rem",
        backgroundColor: "#E2DEEB",
        flexWrap: "wrap",
      }}
    >
      {comingSoonList.map((item, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#18516E",
            borderRadius: "8px",
            padding: "1rem",
            margin: "0.5rem",
            maxWidth: "30%",
            minWidth: "300px",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <img
            src={item.imageSrc}
            alt={item.title}
            style={{ maxWidth: "100%", marginBottom: "1rem" }}
          />
          <Typography variant="subtitle1">{item.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};
const Home: React.FC<Props> = ({ signIn, setSignIn, setIsLogging }) => {
  return (
    <div
      style={{ display: "block", backgroundColor: "#E2DEEB", height: "100vh" }}
    >
      {signIn === true ? (
        <LoginModal setSignIn={setSignIn} setIsLogging={setIsLogging} />
      ) : null}
      {/* <div style={{ display: "flex" }}>
        <h1 style={{ color: "black" }}>Evaluate your NFT communities V2</h1>
      </div> */}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* Add HeroSection, Features, and ComingSoon components */}
        <HeroSection />
        {/* <Features />
        <ComingSoon /> */}
      </Box>
    </div>
  );
};

export default Home;
