import React from "react";
import {
  Container,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useIsLoggedIn } from "../providers/AuthProvider";
import "./style.css";
import { useNavigate } from "react-router-dom";

const FakeRanking = [
  [
    "Bored Ape Yatch Club",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "bored-apes-yatch-club",
  ],
  ["Azuki", "10,019", "1,902", "92,123", "Feb 22", "azuki"],
  ["Doodles", "6,230", "932", "89,119", "Nov 21", "doodles"],
  ["Moonbirds", "4,323", "812", "78,992", "Mar 22", "moonbirds"],
  ["Women Rise", "2,945", "532", "10,112", "Nov 21", "women-rise"],
];

interface Props {
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const RankingTable: React.FC<Props> = ({ setSignIn }) => {
  const navigate = useNavigate();
  const loggedIn = useIsLoggedIn();

  const handleSignIn = (communityId: string) => {
    if (loggedIn) {
      navigate(`/communities/${communityId}`);
    } else {
      setSignIn(true);
      document.body.style.overflowY = "hidden";
    }
  };

  return (
    <div>
      <Container style={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          style={{
            margin: 18,
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#473A77",
          }}
        >
          COMMUNITY RANKING
        </Typography>
        <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "#AFA4E7" }}>
              <TableRow>
                {[
                  "Community Name",
                  "Twitter interactions",
                  "Twitter mentions",
                  "Twitter followers",
                  "Twitter creation date",
                ].map((head) => (
                  <TableCell
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {FakeRanking.map((row) => (
                <TableRow
                  sx={{
                    color: "black",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#D7D0FD",
                    },
                  }}
                  onClick={() => handleSignIn(row[5])}
                >
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[1]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[2]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[3]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[4]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default RankingTable;
