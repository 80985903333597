import React from "react";
import MySpaceCommunitiesTable from "../components/MySpaceCommunitiesTable";
import Carloba from "../assets/carloba.jpg";
import { useAuth } from "../providers/AuthProvider";

const twitterDescription =
  "Founder of @AngryWomenNFT I NFT collector I Feminist I Engineer \n🇫🇷 🇵🇪 \nshe/her\n#womeninnfts #womeninSTEM\n@womenriseNFT @worldofwomennft @PowerOfWomenNFT";
const twitterName = "𝓒𝓪𝓻𝓵𝓸𝓫𝓪 🔮✨";
const twitterUsername = "Tamcar10";

const MySpace = () => {
  const { auth } = useAuth();
  console.log("auth", auth);
  return (
    <div
      style={{
        background: "#E2DEEB",
        width: "100vw",
        height: "100vh",
        marginTop: "0px",
      }}
    >
      <div
        style={{
          marginLeft: "40px",
        }}
      >
        <h3
          style={{
            marginTop: "0px",
            paddingTop: "5px",
            fontFamily: "Kavoon",
            fontSize: "24px",
            color: "#473A77",
            fontWeight: "normal",
          }}
        >
          Very Good Tool
        </h3>
        <h4
          style={{
            marginTop: "0px",
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#473A77",
            fontWeight: "normal",
          }}
        >
          {/* MY SPACE for twitter user {auth?.twitterName || "not logged in"} */}
          MY SPACE
        </h4>
        <div
          style={{
            background: "white",
            borderRadius: "10px",
            width: "1000px",
            height: "200px",
          }}
        >
          <div
            style={{ display: "flex", marginLeft: "10px", paddingTop: "10px" }}
          >
            <div style={{ display: "block" }}>
              <img
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
                src={Carloba}
              />
              <span style={{ display: "block", marginTop: "5px" }}>
                {auth?.twitterName}
              </span>
              <span style={{ display: "block", marginTop: "5px" }}>
                @{auth?.twitterUserName}
              </span>
            </div>
            <div style={{ marginLeft: "20px" }}>
              <p>{twitterDescription}</p>
            </div>
          </div>
        </div>
        <h4
          style={{
            marginTop: "30px",
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#473A77",
            fontWeight: "normal",
          }}
        >
          MY PORTFOLIO INDICATORS
        </h4>
        <h4
          style={{
            marginTop: "0px",
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#473A77",
            fontWeight: "normal",
          }}
        >
          MY COMMUNITIES
        </h4>
        <div style={{ width: "1000px" }}>
          <MySpaceCommunitiesTable />
        </div>
      </div>
    </div>
  );
};

export default MySpace;
