import React from "react";
import {
  Container,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const FakeCommunities = [
  [
    "Women and Weapons",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "women-and-weapons",
  ],
  [
    "Women Rise",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "women-rise",
  ],
  [
    "Power of Women",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "power-of-women",
  ],
  [
    "Flower girls",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "flower-girls",
  ],
  [
    "Not your Bro",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "not-your-bro",
  ],
  [
    "World of Women",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "world-of-women",
  ],
  [
    "Boss Beauties",
    "12,302",
    "2,401",
    "110,032",
    "Aug 21",
    "Ethereum",
    "boss-beauties",
  ],
];

const MySpaceCommunitiesTable = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Container
        style={{
          textAlign: "center",
          marginLeft: "0px",
          paddingLeft: "0px",
          marginRight: "0px",
          paddingRight: "0px",
        }}
      >
        <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "#AFA4E7" }}>
              <TableRow>
                {[
                  "Community Name",
                  "Twitter interactions",
                  "Twitter mentions",
                  "Twitter followers",
                  "Twitter creation date",
                ].map((head) => (
                  <TableCell
                    style={{
                      color: "black",
                      fontWeight: "700",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {FakeCommunities.map((row) => (
                <TableRow
                  sx={{
                    color: "black",
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#D7D0FD",
                    },
                  }}
                  onClick={() => navigate(`/communities/${row[6]}`)}
                >
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[1]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[2]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[3]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row[4]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default MySpaceCommunitiesTable;
