import { createContext, useContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

interface IAuth {
  twitterId?: string;
  twitterName?: string;
  twitterUserName?: string;
  authToken?: string;
  refreshToken?: string;
  expiresAt?: number;
}

interface IAuthContext {
  auth?: IAuth;
  setAuth: (auth?: IAuth) => void;
}

export const AuthContext = createContext<IAuthContext>({ setAuth: () => {} });

const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [auth, setAuth] = useLocalStorage<IAuth>("AUTH");

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth: (a?: IAuth) => {
          console.log("Setting auth!!");
          setAuth(a);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  return auth;
};

export const useIsLoggedIn = () => {
  const authContext = useAuth();
  if (authContext?.auth?.authToken) {
    return true;
  }
  return false;
};

export default AuthProvider;
