import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import "./style.css";

const GET_TOP_TWEETS = gql`
  query GetTopTweetsOnTimeInterval(
    $communityName: String!
    $startDate: String!
    $endDate: String!
  ) {
    top_tweets_on_time_interval(
      community_name: $communityName
      start_date: $startDate
      end_date: $endDate
    ) {
      community_id
      top_tweets {
        date
        tweet_content
      }
    }
  }
`;

interface Props {
  startDate: string;
  endDate: string;
}

interface Tweet {
  tweet_content: string;
  date: string;
}

const CommunityTopNews: React.FC<Props> = ({ startDate, endDate }) => {
  // const communityId = 1;
  const { communityName } = useParams();

  // const [tweets, setTweets] = useState<{ tweet_content: string; date: string }[]>([]);
  const [tweets, setTweets] = useState<Tweet[]>([]);
  const { loading, error, data } = useQuery(GET_TOP_TWEETS, {
    variables: { communityName, startDate, endDate },
  });

  useEffect(() => {
    if (!loading && data.top_tweets_on_time_interval?.top_tweets) {
      const tweetsData = data.top_tweets_on_time_interval.top_tweets;
      setTweets(tweetsData);
    }
  }, [loading, data]);

  return (
    <div className="community-top-news">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : tweets.length === 0 ? (
        <div>
          <h4>
            From {startDate} to {endDate}
          </h4>
          <br />
          <h4 style={{ justifyContent: "center", display: "flex" }}>
            No relevant tweets
          </h4>
        </div>
      ) : (
        <>
          <h4>
            From {startDate} to {endDate}
          </h4>
          {tweets.map((tweet, index) => (
            <div key={index} className="community-top-news__tweet">
              {tweet.tweet_content}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default CommunityTopNews;
