import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import axios from "axios";
import SearchBar from "../components/SearchBar";
import { useQuery, gql } from "@apollo/client";

const GET_COMMUNITIES = gql`
  query GetCommunities {
    communities {
      id
      name
      twitter_id
      twitter_username
      twitter_followers
    }
  }
`;

const Communities: React.FC = () => {
  const { auth } = useAuth();

  const { loading, error, data } = useQuery(GET_COMMUNITIES);
  console.log("test working WORK");
  console.log("data graphql", data);

  const handleTest = (e: React.FormEvent) => {
    e.preventDefault();
    axios.get("auth/test").then((res) => {
      const response_data = res.data;
      console.log(res);
      console.log(response_data);
    });
  };

  const handlePrivate = (e: React.FormEvent) => {
    e.preventDefault();
    axios.get("auth/private").then((res) => {
      const response_data = res.data;
      console.log(res);
      console.log(response_data);
    });
  };

  return (
    <div style={{ background: "#E2DEEB", height: "100%" }}>
      <div style={{ marginLeft: "40px" }}>
        <div
          style={{
            display: "flex",
            marginRight: "80px",
          }}
        >
          <div style={{ width: "860px" }}>
            <h2
              style={{
                marginTop: "0px",
                paddingTop: "10px",
                fontSize: "24px",
                fontFamily: "Kavoon",
                fontWeight: "normal",
                color: "#18516E",
              }}
            >
              Community
            </h2>
          </div>

          {/* <button
            type="button"
            style={{ cursor: "pointer" }}
            onClick={(e) => handleTest(e)}
          >
            TEST
          </button>
          <button
            type="button"
            style={{ cursor: "pointer", marginLeft: "5px" }}
            onClick={(e) => handlePrivate(e)}
          >
            Private
          </button> */}
          <div style={{ marginLeft: "20px", marginTop: "10px" }}>
            <SearchBar />
          </div>
          <div
            style={{
              marginTop: "10px",
              width: "350px",
              marginLeft: "20px",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <button
              style={{
                cursor: "pointer",
                width: "100px",
                height: "30px",
                fontSize: "14px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "10px",
              }}
            >
              @{auth?.twitterUserName}
            </button>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Communities;
