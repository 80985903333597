import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Area chart",
    },
  },
  scales: {
    yAxis: {
      min: 0,
      max: 700,
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = [
  "01/01",
  "15/01",
  "01/02",
  "15/02",
  "01/03",
  "15/03",
  "01/04",
  "15/04",
  "01/05",
  "15/05",
  "01/06",
  "15/06",
  "01/07",
  "15/07",
  "01/08",
  "15/08",
  "01/09",
  "15/09",
  "01/10",
  "15/10",
  "01/11",
  "15/11",
  "01/12",
  "15/12",
];
const data = {
  labels,
  datasets: [
    {
      yAxisID: "yAxis",
      fill: true,
      data: labels.map(() => faker.datatype.number({ min: 200, max: 600 })),
      borderColor: "#F5841B",
      backgroundColor: "#EB9749",
    },
  ],
};

const RankingChart = () => {
  return (
    <div style={{ background: "white", borderRadius: "10px" }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default RankingChart;
