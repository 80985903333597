import React from "react";
import LoginModal from "../components/LoginModal";
import RankingChart from "../components/RankingChart";
import RankingTable from "../components/RankingTable";
import RankingIndicator from "../components/RankingIndicator";

interface Props {
  signIn: boolean;
  setSignIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLogging: React.Dispatch<React.SetStateAction<boolean>>;
}

const Ranking: React.FC<Props> = ({ signIn, setSignIn, setIsLogging }) => {
  return (
    <div
      style={{
        background: "#E2DEEB",
        height: "100vh",
        width: "100vw",
        marginTop: "0px",
      }}
    >
      {signIn === true ? (
        <LoginModal setSignIn={setSignIn} setIsLogging={setIsLogging} />
      ) : null}
      <div>
        <h4
          style={{
            marginTop: "0px",
            fontFamily: "Kavoon",
            fontSize: "20px",
            color: "#473A77",
            fontWeight: "normal",
          }}
        >
          OVERALL MARKET
        </h4>
        <div style={{ width: "1200px", margin: "auto" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "840px" }}>
              <RankingChart />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                marginLeft: "24px",
              }}
            >
              <div style={{ marginTop: "40px" }}>
                <RankingIndicator />
              </div>
              <br />
              <RankingIndicator />
              <br />
              <div style={{ marginBottom: "40px" }}>
                <RankingIndicator />
              </div>
            </div>
          </div>
        </div>
        <RankingTable setSignIn={setSignIn} />
      </div>
    </div>
  );
};

export default Ranking;
