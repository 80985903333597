import { createContext, useState } from "react";

export const User = createContext();

const Context = ({ children }) => {
    const [twitterId, setTwitterId] = useState("");
    const [twitterName, setTwitterName] = useState("");
    const [twitterUsername, setTwitterUsername] = useState("");

    return (
        <User.Provider value={{ twitterIdState: [twitterId, setTwitterId], twitterNameState: [twitterName, setTwitterName], twitterUsernameState: [twitterUsername, setTwitterUsername]}}>
            {children}
        </User.Provider>
    )
}

export default Context